import { post } from '@/utils/request'

//获取目录
export function getModelList(data) {
    return post('/room/room_model/modelList', data)
}

//添加模型
export function addModel(data) {
    return post('/room/room_model/addModel', data)
}
//模型详情
export function getInfo(data) {
    return post('/room/room_model/getModel', data)
}

//模型编辑
export function editModel(data) {
    return post('/room/room_model/editModel', data)
}
//模型删除
export function delModel(data) {
    return post('/room/room_model/delModel', data)
}

//添加规格
export function addSpec(data) {
    return post('/room/room_model/addSpec', data)
}
//删除规格
export function delSpec(data) {
    return post('/room/room_model/delSpec', data)
}
//添加规格值
export function addSpecItem(data) {
    return post('/room/room_model/addItem', data)
}

//删除规格值
export function delSpecItem(data) {
    return post('/room/room_model/delSpecItem', data)
}
// 编辑规格值
export function editSpecItem(data) {
    return post('/room/room_model/editSpecItem', data)
}
// 编辑规格名

export function editSpecName(data) {
    return post('/room/room_model/editSpec', data)
}